import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.createViewReport.toString(),
            defaultSaga({
                constants: constants.createViewReport,
                api: api.createViewReport
            })
        ),
        yield takeLatest(
            constants.deleteViewReport.toString(),
            defaultSaga({
                constants: constants.deleteViewReport,
                api: api.deleteViewReport
            })
        ),
        yield takeLatest(
            constants.editViewReport.toString(),
            defaultSaga({
                constants: constants.editViewReport,
                api: api.editViewReport
            })
        ),
        yield takeLatest(
            constants.getViewReports.toString(),
            defaultSaga({
                constants: constants.getViewReports,
                api: api.getViewReports
            })
        ),
        yield takeLatest(
            constants.getPhoto.toString(),
            defaultSaga({
                constants: constants.getPhoto,
                api: api.getPhoto
            })
        ),
        yield takeLatest(
            constants.getAllPhotos.toString(),
            defaultSaga({
                constants: constants.getAllPhotos,
                api: api.getAllPhotos
            })
        ),
        yield takeLatest(
            constants.appendPhoto.toString(),
            defaultSaga({
                constants: constants.appendPhoto,
                api: api.appendPhoto
            })
        ),
        yield takeLatest(
            constants.removePhoto.toString(),
            defaultSaga({
                constants: constants.removePhoto,
                api: api.removePhoto
            })
        ),
        yield takeLatest(
            constants.getDoc.toString(),
            defaultSaga({
                constants: constants.getDoc,
                api: api.getDoc
            })
        ),
        yield takeLatest(
            constants.getAllDocs.toString(),
            defaultSaga({
                constants: constants.getAllDocs,
                api: api.getAllDocs
            })
        ),
        yield takeLatest(
            constants.appendDoc.toString(),
            defaultSaga({
                constants: constants.appendDoc,
                api: api.appendDoc
            })
        ),
        yield takeLatest(
            constants.removeDoc.toString(),
            defaultSaga({
                constants: constants.removeDoc,
                api: api.removeDoc
            })
        )
    ]);
}