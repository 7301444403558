import React from 'react'
import Private from '../../containers/private-route'
import Page from './component'

const Routes = [
    // <Private
    //     exact
    //     key="SamplingPoints"
    //     path="/sampling-points/:id?"
    //     component={Page} />
]

export default Routes
