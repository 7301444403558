import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { QuestionCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Row,
  Tooltip,
  message,
  Select,
  Form,
  Layout,
  Breadcrumb
} from 'antd';
import request from '../../utils/request';


import NoLayers from './components/no-layers';

import Map from './components/map';
import { PanelLayout } from '../../components';
import { getLayersByMap } from '../../models/mordovia/actions';
import 'ol/ol.css';
import { GEOCODE_MAP_URL_BASE } from '../../environment';
import PointLayers from './components/point-layers';

const { PanelHeader, PanelContent } = PanelLayout;

const SamplingPoints = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation('sampling points');

  const [mapExtent, setMapExtent] = useState([]);
  const [mapId, setMapId] = useState(null);
  const [mapAgronomId, setMapAgronomId] = useState(null);
  const [mapName, setMapName] = useState('');
  const [layerGeohubId, setLayerGeohubId] = useState(null);
  const [layerId, setLayerId] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [layerOptions, setLayerOptions] = useState([]);
  const [columnOptions, setColumnOptions] = useState([]);
  const [identifyingLayerOptions, setIdentifyingLayerOptions] = useState([]);
  const [layersPage, setLayersPage] = useState(1);

  const [currentModalLayer, setCurrentModalLayer] = useState(null);
  const [currentColumn, setCurrentColumn] = useState(null);
  const [currentIdentifyingLayers, setCurrentIdentifyingLayers] = useState([]);

  const history = useHistory();

  const goBack = () => {
    history.replace(`/field-registry/${mapId}/`)
  }

  useEffect(() => {
    if (match?.params?.id) {
      localStorage.setItem('teleagronom-last-opened-map', match.params.id);
      dispatch(getLayersByMap(match?.params?.id));
      setMapId(match.params.id);
    } else {
      const lastId = localStorage.getItem('teleagronom-last-opened-map');
      if (lastId) {
        dispatch(getLayersByMap(match?.params?.id));
        setMapId(match.params.id);
      }
    }
  }, [match.params.id]);

  const getMapAgronomId = async (mapId) => {
    const res = await request.get({
      url: '/api/v1/mordovia/map/',
      body: {
        filter: JSON.stringify({
          map_id_ghb: mapId
        })
      }
    });

    const maps = res.results;
    if (maps?.length) {
      setMapAgronomId(maps[0].id);
      setMapName(maps[0].name);
    }
  };

  useEffect(() => {
    if (mapId) {
      getMapAgronomId(mapId);
    }
  }, [mapId]);

  const getGeohubLayers = (page, pageSize) => {
    console.log('token: ', localStorage.getItem('token'));
    fetch(
      `${GEOCODE_MAP_URL_BASE}/api/layers?offset=${
        (page - 1) * pageSize
      }&limit=${pageSize}`,
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setLayerOptions([...layerOptions, ...data]);
        if (data?.length === pageSize) {
          const newT = setTimeout(() => {
            setLayersPage(layersPage + 1);
            clearTimeout(newT);
          }, 300);
        }
      });
  };

  useEffect(() => {
    getGeohubLayers(layersPage, 30);
  }, [layersPage]);

  const getLayerInfo = (layerId) => {
    fetch(`${GEOCODE_MAP_URL_BASE}/api/layers/${layerId}`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        const extentArray = [];
        data?.extent?.xMin > 0 && extentArray.push(data?.extent?.xMin);
        data?.extent?.yMin > 0 && extentArray.push(data?.extent?.yMin);
        data?.extent?.xMax > 0 && extentArray.push(data?.extent?.xMax);
        data?.extent?.yMax > 0 && extentArray.push(data?.extent?.yMax);

        if (extentArray?.length > 0) {
          setMapExtent(extentArray);
        }
        const columns = data?.columns?.map((col) => ({
          label: col?.name,
          value: col?.name
        }));
        setColumnOptions(columns);
      });
  };

  useEffect(() => {
    if (currentModalLayer) {
      getLayerInfo(currentModalLayer);
    }
  }, [currentModalLayer]);

  const [modalForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const clearForm = () => {
    setModalOpen(false);
    modalForm?.setFieldsValue({
      layer: null,
      column: null,
      identifyingLayers: []
    });
    setCurrentModalLayer(null);
    setCurrentColumn(null);
    setCurrentIdentifyingLayers([]);
  };

  const submit = async () => {
    modalForm?.submit();
    try {
      setIsLoading(true);
      await request.post({
        url: '/api/v1/mordovia/upload_object_data/import_geohub_layer/',
        body: {
          map_id: +mapId,
          layer_id: modalForm.getFieldsValue().layer,
          object_type: 'points',
          point_num_column: modalForm.getFieldsValue().column,
          polygons_layer_id: currentIdentifyingLayers
        }
      });
      clearForm();
      setModalOpen(false);
      fetchPointLayers();
    } catch {
      message.error('backend error');
    } finally {
      setIsLoading(false);
    }
  };

  const getLayers = (filters) =>
    request.get({
      url: '/api/v1/mordovia/layer/',
      body: {
        filter: filters
      }
    });

  const fetchPolygonLayers = async () => {
    const res = await getLayers(
      JSON.stringify({
        object_type: 'polygons',
        map: mapAgronomId,
        is_deleted: false
      }),
    );
    if (res.results) {
      setIdentifyingLayerOptions(
        res.results.map((layer) => ({
            label: layer.name,
            value: layer.id
          })),
      );
    }
  };

  useEffect(() => {
    if (mapAgronomId) {
      fetchPolygonLayers();
    }
  }, [mapAgronomId]);

  const [pointLayers, setPointLayers] = useState([]);
  const fetchPointLayers = async () => {
    const res = await getLayers(
      JSON.stringify({
        object_type: 'points',
        map: mapAgronomId,
        is_deleted: false
      }),
    );
    if (res.results.length) {
      setPointLayers(res.results);
    }
  };
  useEffect(() => {
    if (mapAgronomId) {
      fetchPointLayers();
    }
  }, [mapAgronomId]);

  return (
    <Layout key="page-sampling-points">
      <PanelLayout>
        <PanelHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '20px'
            }}>
            <Button type="ghost" onClick={() => goBack()}>
              <ArrowLeftOutlined />
            </Button>
            <Breadcrumb>
              <Breadcrumb.Item style={{ fontSize: '24px' }}>
                <Link to="/">{t('breadcrumbs.maps')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item style={{ fontSize: '24px' }}>
                <Link to={`/field-registry/${mapId}/`}>{mapName}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item style={{ fontSize: '24px' }}>
                {t('title')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </PanelHeader>
        <PanelContent>
          <Map
            mapExtent={mapExtent}
            mapId={mapId}
            // mapLayer={layerId}
            mapLayer={layerGeohubId}
            style={{ width: '100%' }} />
          <Row style={{ height: '30px' }} />

          {pointLayers.length ? (
            <PointLayers
              pointLayers={pointLayers}
              setModalOpen={setModalOpen}
              reset={() => {
                fetchPointLayers();
              }}
              layerOptions={identifyingLayerOptions}
              mapId={mapId} />
          ) : (
            <NoLayers setModalOpen={setModalOpen} />
          )}

          <Modal
            visible={modalOpen}
            title={(
              <div style={{ display: 'flex', gap: '5px' }}>
                <div>{t('modal title')}</div>
                <Tooltip title={t('modal tooltip')}>
                  <QuestionCircleOutlined
                    style={{
                      display: 'grid',
                      placeItems: 'center',
                      color: 'rgba(0,0,0,0.45)'
                    }} />
                </Tooltip>
              </div>
            )}
            onOk={submit}
            onCancel={() => {
              if (!isLoading) {
                clearForm();
              }
            }}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  if (!isLoading) {
                    clearForm();
                  }
                }}>
                {t('cancel button')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={isLoading}
                onClick={submit}>
                {t('ok button')}
              </Button>
            ]}>
            <Form labelCol={{ span: 24 }} form={modalForm}>
              <Form.Item
                name="layer"
                label={t('layer modal header')}
                rules={[{ required: true, message: t('please fill in') }]}
                tooltip={<div>{t('layer modal header tooltip')}</div>}>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())}
                  placeholder={t('modal placeholder layer')}
                  value={currentModalLayer}
                  options={layerOptions
                    .filter((x) => x?.mapId === Number(match?.params?.id))
                    .map((x) => ({
                        label: x?.name,
                        value: x?.id
                      }))}
                  onChange={(newValue) => {
                    modalForm?.resetFields();
                    // setCurrentType(null);
                    setCurrentColumn(null);
                    setLayerId(newValue);
                    setCurrentModalLayer(newValue);
                    modalForm.setFieldsValue({ layer: newValue });
                  }} />
              </Form.Item>
              <Form.Item
                name="column"
                label={t('column modal header')}
                rules={[{ required: true, message: t('please fill in') }]}
                tooltip={<div>{t('column modal header tooltip')}</div>}>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())}
                  placeholder={t('modal placeholder column')}
                  value={currentColumn}
                  style={{ width: '100%' }}
                  options={columnOptions}
                  onChange={setCurrentColumn} />
              </Form.Item>
              <Form.Item
                name="identifyingLayers"
                label={t('identifying layer modal header')}
                rules={[{ required: false, message: t('please fill in') }]}
                tooltip={
                  <div>{t('identifying layer modal header tooltip')}</div>
                }>
                <Select
                  mode="multiple"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())}
                  placeholder={t('modal placeholder identifying layer')}
                  value={currentIdentifyingLayers}
                  style={{ width: '100%' }}
                  options={identifyingLayerOptions}
                  onChange={setCurrentIdentifyingLayers} />
              </Form.Item>
            </Form>
          </Modal>
        </PanelContent>
      </PanelLayout>
    </Layout>
  );
};

export default SamplingPoints;
