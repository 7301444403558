import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'antd';

import '../style.less';

export default ({
    openModal
}) => {
    const { t } = useTranslation('borshevik');

    return (
        <>
            <div className="empty-info-box">
                <div style={{ marginBottom: '15px' }}>
                    {t('empty text')}
                </div>
            </div>
            <Row className="button-row">
                <Col span={9} />
                <Col span={5}>
                    <Button onClick={() => openModal(true)} type="primary">
                        {t('empty button text')}
                    </Button>
                </Col>
                <Col span={10} />
            </Row>
        </>
    );
};