import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const reportsList = state => state.models[name].reports;
export const docsList = state => state.models[name].docs;
export const photoList = state => state.models[name].photos;
export const uploadedDoc = state => state.models[name].uploadedDoc;
export const uploadedPhoto = state => state.models[name].uploadedPhoto;
