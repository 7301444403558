import React, {useState, useEffect} from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Modal, Row, message, notification, Col, Select, Form, Space, Layout, Tabs } from 'antd';
import Map from './components/map';
import { Title, PanelLayout, SideBar } from '../../components';
import CalculatorsTable from './components/calculators-table';
import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import { getDistricts, getGeocodeFeature, getLayersByMap, getObjectByLayer, getObjectsRegistryList, getSyncedLayers, syncField } from '../../models/mordovia/actions';
import { currDistricts, currentGeocodeFeature, currentLayersByMap, currentObjectByLayer, currentObjectByLayerCount, currentObjectRegistryList, currentSyncedField, currentSyncedLayers } from '../../models/mordovia/selectors';
import { getUser, mapLinker } from '../../models/account/selectors';
import { getProfile, linkMapToUser } from '../../models/account/actions';
import { usrListResponse } from '../../models/sample-log/selectors';
import { getUsersList } from '../../models/sample-log/actions';
import proj4 from 'proj4';
import { useForm } from 'antd/lib/form/Form';
import options from '../../options';
import 'ol/ol.css';
import VectorSource from 'ol/source/Vector.js';
import Point from 'ol/geom/Point.js'
import MultiPoint from 'ol/geom/MultiPoint.js'
import LineString from 'ol/geom/LineString.js';
import Polygon from 'ol/geom/Polygon.js';
import MultiLineString from 'ol/geom/MultiLineString.js';
import MultiPolygon from 'ol/geom/MultiPolygon.js';
import Feature from 'ol/Feature.js';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getCultures } from '../../models/cultures/actions';
import { getCulturesCatalog } from '../../models/cultures/selectors';
import { GEOCODE_MAP_URL_BASE } from '../../environment';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import PolygonsTable from './components/polygons-table';
import { getViewReports } from '../../models/borshevik/actions';
import GeneralInfo from './components/general-info';
const { PanelHeader, PanelContent } = PanelLayout;

const { TabPane } = Tabs

const FieldRegistry = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation('field registry');
    const { t: t2 } = useTranslation('borshevik');

    const fieldShape = useSelector(state => currentGeocodeFeature(state));
    const currentUser = useSelector(state => getUser(state));
    const usersNew = useSelector(state => usrListResponse(state));
    const registryObjectsList = useSelector(state => currentObjectRegistryList(state));
    const mapLinkChecker = useSelector(state => mapLinker(state));
    const currentSyncLayers = useSelector(state => currentSyncedLayers(state));
    const curObjs = useSelector(state => currentObjectByLayer(state));
    const curCnt = useSelector(state => currentObjectByLayerCount(state));
    const cultures = useSelector(state => getCulturesCatalog(state));
    const syncResult = useSelector(state => currentSyncedField(state));
    const layersByMap = useSelector(state => currentLayersByMap(state));

    useEffect(() => {
        // console.log('CURRENT LAYERS BY MAP ID', layersByMap);
    }, [layersByMap]);

    useEffect(() => {
        if (match?.params?.id) {
            // localStorage.setItem('teleagronom-last-opened-map', match.params.id);
            dispatch(getLayersByMap(match?.params?.id));
            setMapId(match.params.id)
        } else {
            const lastId = localStorage.getItem('teleagronom-last-opened-map');
            if (lastId) {
                dispatch(getLayersByMap(match?.params?.id));
                setMapId(match.params.id)
            }
        }
    }, [match.params.id]);
    

    const [fullCurrentLayer, setFullCurrentLayer] = useState(null);
    const [currentRegistryObject, setCurrentRegistryObject] = useState([]);
    const [fullUsersList, setFullUsersList] = useState([]);
    const [mapExtent, setMapExtent] = useState([]);
    const [mapId, setMapId] = useState(null);
    const [layerGeohubId, setLayerGeohubId] = useState(null);
    const [layerId, setLayerId] = useState(null);
    const [usrPage, setUsrPage] = useState(1);
    const [activeColumns, setActiveColumns] = useState([
        'field_num', 'owner_user',
        'years', 'area', 'culture'
    ]);
    const [activeColumnInstances, setActiveColumnInstances] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentType, setCurrentType] = useState('polygon');
    const [currentColumn, setCurrentColumn] = useState(null);
    const [layerOptions, setLayerOptions] = useState([]);
    const [columnOptions, setColumnOptions] = useState([]);
    const [currentModalLayer, setCurrentModalLayer] = useState(null);

    useEffect(() => {
        console.log('layersByMap', layersByMap)
    }, [layersByMap]);

    useEffect(() => {
        if (mapLinkChecker?.creationSuccessful?.error?.length > 0) {
            notification.error({
                message: 'Ошибка!',
                description: 'При создании карты произошла ошибка. Обновите страницу',
            });
        }
    }, [mapLinkChecker]);

    useEffect(() => {
        const { id, email } = currentUser;
    }, [currentUser]);

    useEffect(() => {
        if (registryObjectsList?.length === 1 && registryObjectsList[0] === 'error') {
            notification.error({
                message: 'Ошибка',
                description: 'Нет доступа к базе данных',
            });
        }
    }, [registryObjectsList]);
    
    useEffect(() => {
        const newUsersPortion = usersNew?.results;
        if (newUsersPortion?.length > 0) {
            const uniqueKeys = ['id', 'name'];
            setFullUsersList([...fullUsersList, ...newUsersPortion?.filter(x => ![...fullUsersList].map(y => y?.id)?.includes(x?.id))].filter((value, index, self) =>
                self.findIndex(v => uniqueKeys.every(k => v[k] === value[k])) === index
            ));
        }
        const isNext = usersNew?.next?.length > 0;
        if (isNext) {
            const reloaderTimeout = setTimeout(() => {
                setUsrPage(usrPage + 1);
                clearTimeout(reloaderTimeout);
            }, 500);
        }
    }, [usersNew]);

    useEffect(() => {
        if (fieldShape?.error?.length > 0) {
            message.error('Ошибка при получении объекта с geohub')
        }
    }, [fieldShape]);

    useEffect(() => {
        if (usrPage && usrPage > 0 && currentUser?.id) {
            dispatch(getUsersList({
                page: usrPage,
                belong: currentUser?.id
            }));
        }
    }, [usrPage, currentUser]);

    const [layersPage, setLayersPage] = useState(1);

    const getGeohubLayers = (page, pageSize) => {
        fetch(`${GEOCODE_MAP_URL_BASE}/api/layers?offset=${(page - 1)*pageSize}&limit=${pageSize}`, {
            mode: 'cors',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                // 'Access-Control-Allow-Origin':'localhost:3000',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            return res.json()
        })
        .then((data) => {
            setLayerOptions([...layerOptions, ...data]);
            if (data?.length === pageSize) {
                const newT = setTimeout(() => {
                    setLayersPage(layersPage + 1);
                    clearTimeout(newT);
                }, 300);
            }
        });
    };
    
    useEffect(() => {
        getGeohubLayers(layersPage, 30);
    }, [layersPage]);
    
    useEffect(() => {
        dispatch(getProfile());
        // dispatch(getSyncedLayers());
        dispatch(getCultures('?page_size=1000'));
    }, []);

    // useEffect(() => {
    //     if (currentUser?.geohub_map_id) {
    //         setMapId(currentUser?.geohub_map_id);
    //     }
    // }, [currentUser]);

    const setActiveField = (fieldItem) => {
        const { feature_id } = fieldItem?.registry_object;
        setLayerGeohubId(fieldItem?.registry_object?.layer?.layer_id_ghb);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        if (feature_id && feature_id > 0) {
            const urlString = `${GEOCODE_MAP_URL_BASE}/api/layers/${fieldItem?.registry_object?.layer?.layer_id_ghb}/features/${feature_id}`;
            fetch(urlString, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
            }).then((res) => res.json())
            .then((data) => {
                const { properties, geometry } = data;
                const { tsentroid } = properties;
                const { type, coordinates } = geometry;

                const vectorSrc = new VectorSource();

                let featureInstance;

                if (type === 'Point') {
                    featureInstance = new LineString([coordinates, [parseFloat(coordinates[0]) + 0.0001, parseFloat(coordinates[1]) + 0.0001]]);
                }
                if (type === 'LineString') {
                    featureInstance = new LineString(coordinates);
                }
                if (type === 'MultiLineString') {
                    featureInstance = new MultiLineString(coordinates);
                }
                if (type === 'Polygon') {
                    featureInstance = new Polygon(coordinates);
                }
                if (type === 'MultiPoint') {
                    featureInstance = new Polygon(coordinates?.length > 1 ? [coordinates] : [coordinates[0], [parseFloat(coordinates[0][0]) + 0.0001, parseFloat(coordinates[0][1]) + 0.0001]]);
                }
                if (type === 'MultiPolygon') {
                    featureInstance = new MultiPolygon(coordinates);
                }

                const resFeat = new Feature({ geometry: featureInstance });

                vectorSrc.addFeature(resFeat);

                setMapExtent(vectorSrc.getExtent());
            });
        }
        if (fieldItem?.registry_object?.layer?.id) {
            setLayerId(fieldItem?.registry_object?.layer?.id);
        }
    };

    const clearAllFields = () => {
        modalForm?.setFieldsValue({
            layer: null,
            type: null,
            column: null
        });
        setCurrentModalLayer(null);
        setCurrentColumn(null);
        setColumnOptions([]);
        setShowModal(false);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (syncResult?.isResult && syncResult?.results?.length > 0) {
            notification.success({
                message: t('sync success ok title'),
                description: t('sync success ok text')
            });

            // setCurrentRegistryObject(syncResult?.results);

            const resultingArray = [];
            syncResult?.results?.slice(0, 9)?.forEach(curntObj => {
                if (curntObj?.year_group?.years?.length === 0) {
                    resultingArray?.push(curntObj);
                }
                if (curntObj?.year_group?.years?.length > 0) {
                    curntObj?.year_group?.years?.sort((a, b) => b.year - a.year)?.forEach(curntYear => {
                        let insertableObject = {
                            vega_key: curntObj?.vega_key,
                            id: curntObj?.id,
                            field_num: curntObj?.field_num,
                            year_group: curntObj?.year_group,
                            registry_object: curntObj?.registry_object,
                            region: curntObj?.region,
                            district: curntObj?.district,
                            owner_user: curntObj?.owner_user,
                            user: curntObj?.user,
                            area: curntObj?.area,
                            soil_type: curntObj?.soil_type,
                            year: curntYear?.year,
                            mechanical_composition: curntYear?.mechanical_composition,
                            degree_of_soil_moisture: curntYear?.degree_of_soil_moisture,
                            sowing_date: curntYear?.sowing_date,
                            culture: curntYear?.culture,
                            preceding_culture: curntYear?.preceding_culture,
                            planned_yield: curntYear?.planned_yield,
                            depth_of_arable_layer: curntYear?.depth_of_arable_layer,
                            fertilizer_action_year: curntYear?.fertilizer_action_year,
                            yearRepetitions: curntObj?.year_group?.years?.length
                        };
    
                        resultingArray?.push(insertableObject);
                        // resultingArray?.push({
                        //     // ...curntYear,
                        //     ...curntObj,
                        //     year: curntYear?.year,
                        //     depth_of_arable_layer: curntYear?.depth_of_arable_layer,
                        //     fertilizer_action_year: curntYear?.fertilizer_action_year,
                        //     planned_yield: curntYear?.planned_yield,
                        //     preceding_culture: curntYear?.preceding_culture,
                        //     culture: curntYear?.culture,
                        //     sowing_date: curntYear?.sowing_date,
                        //     degree_of_soil_moisture: curntYear?.degree_of_soil_moisture,
                        //     mechanical_composition: curntYear?.mechanical_composition
                        // });
                    });
                }
            });
            setCurrentRegistryObject(resultingArray);

            closeModal();
        }
        if (!syncResult?.isResult && syncResult?.error === 'backend error') {
            notification.error({
                message: t('Error'),
                description: t('Backend error')
            });

            clearAllFields();
        }
    }, [syncResult]);

    const handleModalOk = () => {
        modalForm?.submit();
        
        if (layerId && currentType && currentColumn) {
            if (currentSyncLayers && currentSyncLayers.includes && currentSyncLayers?.includes(layerId)) {
                notification.error({
                    message: t('sync success fail title'),
                    description: t('sync success fail text')
                });

                clearAllFields();
            } else {
                dispatch(syncField({
                    map_id: match.params.id,
                    layer_id: layerId,
                    field_num_column: currentColumn
                }));

                const refreshTimeout = setTimeout(() => {
                    window.location.reload();
                    // dispatch(getSyncedLayers());
                    // dispatch(getObjectByLayer({
                    //     page: 1,
                    //     pageSize: 10,
                    //     layerId: layerId
                    // }));
                    // getLayerInfo(layerId);
                    clearTimeout(refreshTimeout);
                }, 1000);
            }
            // dispatch(syncField({
            //     layer_id: layerId,
            //     field_num_column: currentColumn
            // }));
        }
    };

    useEffect(() => {
        // console.log('fullCurrentLayer fullCurrentLayer fullCurrentLayer ', fullCurrentLayer);
    }, [fullCurrentLayer]);
    

    const getLayerInfo = (layerId) => {
        fetch(`${GEOCODE_MAP_URL_BASE}/api/layers/${layerId}`, {
            mode: 'cors',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                // 'Access-Control-Allow-Origin':'localhost:3000',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            return res.json()
        })
        .then((data) => {
            let extentArray = [];
            data?.extent?.xMin > 0 && extentArray.push(data?.extent?.xMin);
            data?.extent?.yMin > 0 && extentArray.push(data?.extent?.yMin);
            data?.extent?.xMax > 0 && extentArray.push(data?.extent?.xMax);
            data?.extent?.yMax > 0 && extentArray.push(data?.extent?.yMax);

            if (extentArray?.length > 0) {
                setMapExtent(extentArray);
            }
            setFullCurrentLayer(data);
            setColumnOptions(data?.columns?.map(dataCol => {
                return {
                    label: dataCol?.name,
                    value: dataCol?.name
                }
            }));
        });
    };

    useEffect(() => {
        if (currentModalLayer) {
            getLayerInfo(currentModalLayer);
        }
    }, [currentModalLayer]);

    useEffect(() => {
        getLayerInfo(layerGeohubId);
    }, [layerGeohubId])
    
    
    useEffect(() => {
        if (layerId) {
            dispatch(getViewReports({
                id: layerId
            }));
        }
    }, [layerId]);
    
    useEffect(() => {
        // setCurrentRegistryObject(curObjs);
        const resultingArray = [];
        curObjs?.forEach(curntObj => {
            if (curntObj?.year_group?.years?.length === 0) {
                resultingArray?.push(curntObj);
            }
            if (curntObj?.year_group?.years?.length > 0) {
                curntObj?.year_group?.years?.sort((a, b) => b.year - a.year)?.forEach(curntYear => {
                    let insertableObject = {
                        vega_key: curntObj?.vega_key,
                        id: curntObj?.id,
                        field_num: curntObj?.field_num,
                        year_group: curntObj?.year_group,
                        registry_object: curntObj?.registry_object,
                        region: curntObj?.region,
                        district: curntObj?.district,
                        owner_user: curntObj?.owner_user,
                        user: curntObj?.user,
                        area: curntObj?.area,
                        soil_type: curntObj?.soil_type,
                        year: curntYear?.year,
                        mechanical_composition: curntYear?.mechanical_composition,
                        degree_of_soil_moisture: curntYear?.degree_of_soil_moisture,
                        sowing_date: curntYear?.sowing_date,
                        culture: curntYear?.culture,
                        preceding_culture: curntYear?.preceding_culture,
                        planned_yield: curntYear?.planned_yield,
                        depth_of_arable_layer: curntYear?.depth_of_arable_layer,
                        fertilizer_action_year: curntYear?.fertilizer_action_year,
                        yearRepetitions: curntObj?.year_group?.years?.length
                    };

                    resultingArray?.push(insertableObject);
                    // resultingArray?.push({
                    //     // ...curntYear,
                    //     ...curntObj,
                    //     year: curntYear?.year,
                    //     depth_of_arable_layer: curntYear?.depth_of_arable_layer,
                    //     fertilizer_action_year: curntYear?.fertilizer_action_year,
                    //     planned_yield: curntYear?.planned_yield,
                    //     preceding_culture: curntYear?.preceding_culture,
                    //     culture: curntYear?.culture,
                    //     sowing_date: curntYear?.sowing_date,
                    //     degree_of_soil_moisture: curntYear?.degree_of_soil_moisture,
                    //     mechanical_composition: curntYear?.mechanical_composition
                    // });
                });
            }
        });
        setCurrentRegistryObject(resultingArray);
    }, [curObjs]);
    
    useEffect(() => {
        if ((registryObjectsList?.length > 0 && registryObjectsList?.[0] !== 'error') || registryObjectsList?.length === 0) {
            setCurrentRegistryObject(registryObjectsList);
        }
    }, [registryObjectsList]);

    const openFieldYear = (year, record, additionalParams = {}) => {
        const additionalParamKeys = Object.keys(additionalParams);
        if (additionalParamKeys?.length > 0) {
            const insertableObj = {};
            additionalParamKeys.forEach(paramKey => {
                insertableObj[paramKey] = additionalParams[paramKey];
            });
            // console.log('INSERTABLE CHECK', insertableObj);
            const translatedValues = JSON.stringify(insertableObj);
            sessionStorage.setItem('object-registry/filters', translatedValues);
        }
        history.push(`/representation/${year ?? record?.year}/${record?.vega_key}`);
    };
    const [modalForm] = Form.useForm();

    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showFileModal, setShowFileModal] = useState(false)
    const [showDocsModal, setShowDocsModal] = useState(false)

    const [layerIdHolder, setLayerIdHolder] = useState();

    const [currentTab, setCurrentTab] = useState("1");

    const [currentMapId, setCurrentMapId] = useState()

    useEffect(() => {
        setCurrentMapId(localStorage.getItem('teleagronom-last-opened-map'));
    }, [])
    
    
    return (
        <Layout key="page-field-registry">
            {/* <SideBar /> */}
            <PanelLayout>
                <PanelHeader>
                    <Title title={(
                        <div style={{ marginLeft: '-15px', display: 'flex', alignItems: 'center' }}>
                            <Button onClick={() => window.location.replace("/")}>
                                <ArrowLeftOutlined />
                            </Button>
                            <span className="header-link-inactive" style={{ paddingLeft: '10px' }}>{t('my maps')} / </span>
                            <span className="header-link-inactive" style={{ paddingLeft: '10px' }}>{t('teleagronom')} / </span>
                            <span className={layerId ? "header-link-inactive" : ""} style={{ paddingLeft: '10px' }}>{t('borshevik')}{layerId && ' / '}</span>
                            {layerId && (<span style={{ paddingLeft: '10px' }}>{layersByMap?.filter(x => x?.id === layerId)?.[0]?.name}</span>)}
                        </div>
                    )} />
                </PanelHeader>
                <PanelContent>
                    <Tabs
                        onChange={setCurrentTab}
                        activeKey={currentTab}>
                        <TabPane
                            tab={(
                                <span>
                                    Полигоны обследования
                                </span>
                            )}
                            key="1">
                                <Map
                                    mapExtent={mapExtent}
                                    mapId={mapId}
                                    // mapLayer={layerId}
                                    mapLayer={layerGeohubId}
                                    style={{ width: '100%' }} />
                                <Row style={{ height: '30px' }} />
                                <CalculatorsTable
                                    openDeleteModal={() => setShowDeleteModal(true)}
                                    openEditModal={() => setShowUpdateModal(true)}
                                    setTempId={setLayerIdHolder}
                                    openFieldYear={openFieldYear}
                                    history={history}
                                    syncedLayers={currentSyncLayers}
                                    setActiveField={setActiveField}
                                    dataSize={layerId ? curCnt : layersByMap?.filter(x => x?.name?.toLowerCase()?.includes('борщевик'))?.length}
                                    setGeneralLayer={setLayerId}
                                    generalLayer={layerId}
                                    openModal={setShowModal}
                                    layerOptions={layersByMap?.filter(x => x?.name?.toLowerCase()?.includes('борщевик'))}
                                    // layerOptions={layersByMap}
                                    activeColumns={activeColumns}
                                    setActiveColumns={setActiveColumns}
                                    dataSource={currentRegistryObject}
                                    openField={setActiveField}
                                    className="map-table" />
                            </TabPane>
                        <TabPane
                            tab={(
                                <span>
                                    Справочная информация
                                </span>
                            )}
                            key="2">
                                <GeneralInfo />
                            </TabPane>
                    </Tabs>
                    
                    <Modal
                        visible={showModal}
                        title={t('modal title')}
                        onOk={handleModalOk}
                        onCancel={clearAllFields}
                        footer={[
                            <Button key="back" onClick={clearAllFields}>
                                {t('cancel button')}
                            </Button>,
                            <Button key="submit" type="primary" onClick={handleModalOk}>
                                {t('ok button')}
                            </Button>
                        ]}
                    >
                        <Form
                            labelCol={{ span: 24}}
                            form={modalForm}>
                                <Form.Item name={"layer"} label={t('layer modal header')} rules={[{ required: true, message: t('please fill in') }]}>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                                        placeholder={t2('modal placeholder layer')}
                                        value={currentModalLayer}
                                        options={layerOptions.filter(x => x?.mapId === Number(currentMapId)).map(x => {
                                            return {
                                                label: x?.name,
                                                value: x?.id
                                            }
                                        })}
                                        onChange={(newValue) => {
                                            modalForm?.resetFields();
                                            setCurrentColumn(null);
                                            setLayerId(newValue);
                                            setCurrentModalLayer(newValue);
                                            modalForm.setFieldsValue({ layer: newValue });
                                        }} />
                                </Form.Item>

                                <Form.Item name={"column"} label={t2('column modal header')} rules={[{ required: true, message: t('please fill in') }]}>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                                        placeholder={t2('modal placeholder name')}
                                        value={currentColumn}
                                        style={{ width: '100%' }}
                                        options={columnOptions}
                                        onChange={setCurrentColumn} />
                                </Form.Item>
                        </Form>
                    </Modal>


                    <Modal
                        visible={showUpdateModal}
                        title={t2('update modal title')}
                        // onOk={handleModalOk}
                        onCancel={() => setShowUpdateModal(false)}
                        footer={[
                            <Button key="back" onClick={() => setShowUpdateModal(false)}>
                                {t('cancel button')}
                            </Button>,
                            <Button key="submit"
                            // onClick={handleModalOk}
                            type="primary">
                                {t('ok button')}
                            </Button>
                        ]}
                    >
                        <div className="warning-red">
                        <span style={{ color: 'red' }}>!</span>{t2('warning red update text')}
                        </div>
                        <div style={{ marginTop: '15px' }} className="maintext">
                        {t2('update text')}
                        </div>
                    </Modal>


                    <Modal
                        visible={showDeleteModal}
                        title={t2('delete modal title')}
                        // onOk={handleModalOk}
                        onCancel={() => setShowDeleteModal(false)}
                        footer={[
                            <Button key="back" onClick={() => setShowDeleteModal(false)}>
                                {t('cancel button')}
                            </Button>,
                            <Button key="submit"
                            // onClick={handleModalOk}
                            danger type="primary" >
                                {t2('delete button')}
                            </Button>
                        ]}
                    >
                        <div className="maintext">
                        {t2('delete text one')}
                        </div>
                        <div style={{ marginBottom: '25px' }} className="maintext">
                        {t2('delete text two')}
                        </div>
                        <div className="warning-red">
                        <span style={{ color: 'red' }}>!</span>{t2('warning red delete text')}
                        </div>
                    </Modal>
                    <Modal
                        visible={showFileModal}
                        title={t2('file modal title')}
                        // onOk={handleModalOk}
                        onCancel={() => setShowDeleteModal(false)}
                        footer={[
                            <Button key="back" onClick={() => setShowDeleteModal(false)}>
                                {t('cancel button')}
                            </Button>,
                            <Button key="submit"
                            // onClick={handleModalOk}
                            danger type="primary" >
                                {t2('delete button')}
                            </Button>
                        ]}
                    >
                        <div className="maintext">
                        {t2('delete text one')}
                        </div>
                        <div style={{ marginBottom: '25px' }} className="maintext">
                        {t2('delete text two')}
                        </div>
                        <div className="warning-red">
                        <span style={{ color: 'red' }}>!</span>{t2('warning red delete text')}
                        </div>
                    </Modal>
                    <Modal
                        visible={showDocsModal}
                        title={t2('docs modal title')}
                        // onOk={handleModalOk}
                        onCancel={() => setShowDeleteModal(false)}
                        footer={[
                            <Button key="back" onClick={() => setShowDeleteModal(false)}>
                                {t('cancel button')}
                            </Button>,
                            <Button key="submit"
                            // onClick={handleModalOk}
                            danger type="primary" >
                                {t2('delete button')}
                            </Button>
                        ]}
                    >
                        <div className="maintext">
                        {t2('delete text one')}
                        </div>
                        <div style={{ marginBottom: '25px' }} className="maintext">
                        {t2('delete text two')}
                        </div>
                        <div className="warning-red">
                        <span style={{ color: 'red' }}>!</span>{t2('warning red delete text')}
                        </div>
                    </Modal>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default FieldRegistry;