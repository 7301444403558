import React, { Component, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
    Col, Form, Input, Row, Select, notification, Button, Space
} from 'antd';
import Icon from '@ant-design/icons';
import Popover from './action-popover';
import cn from '../../../utils/cn';

import { selectors, actions } from '../../../models';
import { InfoMessage, Table, TableFooter, Tooltip } from '../../../components';

import NewDataForm from './new-data-form';
import ColumnSelect from './column-select';
import { toFixed } from '../../../utils/utils';
import { GEOCODE_MAP_URL_BASE } from '../../../environment';

const {
    getObjectByLayer,
    // getCultures
} = actions;
const {
    currentObjectByLayer,
    getUser,
    // getCulturesCatalog
} = selectors;

const mstp = state => ({
    user: getUser(state),
    // cultures: getCulturesCatalog(state)
});

const mdtp = ({
    getObjectByLayer,
    // getCultures
});

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;
const DEFAULT_TABLE_PAGE = 1;

@withRouter
@connect(mstp, mdtp)
@withTranslation('calculators table')
@cn('calculators-table')
export default class CalculatorsTable extends Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.formRef = React.createRef();
        // this.focusTextInput = this.focusTextInput.bind(this);
    }

    state = {
        page: DEFAULT_TABLE_PAGE,
        page_size: ROW_DIVIDER,
        infoMessageVisible: false,
        deleteId: null,
        sortingOrder: null
    };

    componentDidMount() {
        // const { getCultures } = this.props;
        // getCultures();

        setTimeout(() => {
            this.getFilteredFields();
        }, 500);

        // if (sessionStorage.getItem('object-registry/filters')?.length > 0) {
        //     this.getFilteredFields();
        // }
    }

    onPageSizeChange = (page_size) => {
        const {
            page,
            layer,
            year,
            owner_user,
            culture,
        } = this.state;

        this.setState({
            page_size
        }, () => {
            const copiedVals = {
                page: page,
                page_size: page_size,
                year: year,
                layer: layer,
                owner_user: owner_user,
                culture: culture
            };
            const translatedValues = JSON.stringify(copiedVals);
            window.onbeforeunload = () => {
                sessionStorage.setItem('object-registry/filters', translatedValues);
            };
            
            this.getFilteredFields();
        });
    };

    onCurrentPageChange = (page) => {
        const {
            page_size,
            layer,
            year,
            owner_user,
            culture,
        } = this.state;

        this.setState({
            page
        }, () => {
            const copiedVals = {
                page: page,
                page_size: page_size,
                year: year,
                layer: layer,
                owner_user: owner_user,
                culture: culture
            };
            const translatedValues = JSON.stringify(copiedVals);
            window.onbeforeunload = () => {
                sessionStorage.setItem('object-registry/filters', translatedValues);
            };

            this.getFilteredFields();
        });
    };

    get getYears() {
        const date = new Date();
        let year = Number(date.getFullYear());
        // let step = 10;
        let step = 6;
        const years = [];
        // while (step >= 0 && year !== 2017) {
        while (year !== 2017) {
            years.push(year--);
            step--;
        }
        return years;
    }

    get initRequest() {
        const { page, page_size } = this.state;
        const { user } = this.props;
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        return `?${page_size_url}&${page_url}`;
    }

    getFilteredFields = () => {
        const {
            page_size,
            page,
            year,
            owner_user,
            culture,
            sortingOrder
        } = this.state;
        let sorterString;
        if (sortingOrder) {
            const { order, columnKey } = sortingOrder;

            let resColKey = columnKey;
            
            if (resColKey === 'object_type') {
                resColKey = 'registry_object';
            }
            if (resColKey === 'owner') {
                resColKey = 'user';
            }

            if (order === 'ascend') {
                sorterString = columnKey;
            }
            if (order === 'descend') {
                sorterString = `-${columnKey}`
            }
        }
        
        const { getCalculatorsMordovia, user, generalLayer = null, getObjectByLayer, setGeneralLayer } = this.props;

        const savedProps = sessionStorage.getItem('object-registry/filters');

        if (savedProps?.length > 0) {
            const preFilters = JSON.parse(savedProps);
            sessionStorage.removeItem('object-registry/filters');

            if (!generalLayer && preFilters?.layer) {
                setGeneralLayer(preFilters?.layer);
            }
            
            this.formRef.current?.setFieldsValue({
                layer: preFilters?.layer,
                // year: preFilters?.year,
                // culture: preFilters?.culture,
                // owner_user: preFilters?.owner_user
            });

            this.formRef.current?.setFieldsValue({
                // layer: preFilters?.layer,
                year: preFilters?.year,
                culture: preFilters?.culture,
                owner_user: preFilters?.owner_user
            });

            this.setState({
                layer: preFilters?.layer,
                page: preFilters?.page,
                page_size: preFilters?.page_size,
                year: preFilters?.year,
                culture: preFilters?.culture,
                owner_user: preFilters?.owner_user
            }, () => {
                getObjectByLayer({
                    page: preFilters?.page,
                    pageSize: preFilters?.page_size,
                    layerId: preFilters?.layer,
                    sorterString: sorterString,
                    filterObj: {
                        ownerUser: preFilters?.owner_user,
                        year: preFilters?.year,
                        culture: preFilters?.culture
                    }
                });
            });
        } else {
            if (generalLayer) {
                getObjectByLayer({
                    page: page,
                    pageSize: page_size,
                    layerId: generalLayer ?? year,
                    sorterString: sorterString,
                    filterObj: {
                        ownerUser: owner_user,
                        year: year,
                        culture: culture
                    }
                });
            }
        }
    };

    onChange = (value, values) => {
        const {
            page_size,
            page
        } = this.state;
        this.setState(value, () => {
            if (Object.keys(value)?.[0] === 'layer') {
                const copiedVals = { layer: values?.layer };
                copiedVals.page = page;
                copiedVals.page_size = page_size;
                const translatedValues = JSON.stringify(copiedVals);
                window.onbeforeunload = () => {
                    sessionStorage.setItem('object-registry/filters', translatedValues);
                };

                if (this.formRef.current?.getFieldsValue()?.year) {
                    this.formRef.current?.setFieldsValue({
                        year: null,
                        culture: null,
                        owner_user: null
                    });
                } else {
                    this.formRef.current?.setFieldsValue({
                        // year: null,
                        culture: null,
                        owner_user: null
                    });
                }
                
                this.setState({
                    year: null,
                    culture: null,
                    owner_user: null
                }, () => {
                    this.setState({ page: 1 }, () => {
                        this.getFilteredFields();
                    });
                });
            } else {
                const copiedVals = { ...values };
                copiedVals.page = page;
                copiedVals.page_size = page_size;
                const translatedValues = JSON.stringify(copiedVals);
                window.onbeforeunload = () => {
                    sessionStorage.setItem('object-registry/filters', translatedValues);
                };
                this.setState({ page: 1 }, () => {
                    this.getFilteredFields();
                });
            }
        });
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        });
    };

    get requestsColumns() {
        const {
            setTempId = () => {},
            openEditModal = () => {},
            openDeleteModal = () => {},
            t
        } = this.props;
        return [
            {
                title: t('header layer name'),
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                render: (value, record) => {
                    return (
                        <span className="hoverable-link" onClick={() => this.selectLayer(record?.id)}>
                            {value}
                        </span>
                    )
                }
            },
            {
                title: t('header polygon num'),
                dataIndex: 'num_of_objects',
                key: 'num_of_objects',
                sorter: true
            },
            {
                title: t('header update date'),
                dataIndex: 'updated',
                key: 'updated',
                sorter: true,
                render: value => value ? moment(value?.split('T')?.[0])?.format('DD.MM.YYYY') : null
            },
            {
                title: '',
                key: 'actions',
                render: (value, record) => (
                <Tooltip
                    placement="bottom"
                    title={(
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Button onClick={() => {
                                setTempId(record?.id);
                                openEditModal();
                            }}
                            style={{ width: '100%' }}
                            >
                                Обновить
                            </Button>
                            <Button onClick={() => {
                                setTempId(record?.id);
                                openDeleteModal();
                            }}
                            style={{ width: '100%', marginTop: '15px' }}
                            >
                                Удалить
                            </Button>
                        </div>
                    )}
                >
                    <Button>:</Button>
                </Tooltip>)
            }
        ];
    }

    get requestsColumnsTwo() {
        const {
            t
        } = this.props;
        return [
            {
                title: t('header polygon name'),
                dataIndex: 'field_num',
                key: 'field_num'
            },
            {
                title: t('header obsled date'),
                dataIndex: 'field_num',
                key: 'field_num'
            },
            {
                title: t('header grow fact'),
                dataIndex: 'field_num',
                key: 'field_num'
            },
            {
                title: t('header veg phase'),
                dataIndex: 'field_num',
                key: 'field_num'
            },
            {
                title: t('header care fact'),
                dataIndex: 'field_num',
                key: 'field_num'
            },
            {
                title: t('header care date'),
                dataIndex: 'field_num',
                key: 'field_num'
            },
            {
                title: t('header photo report'),
                dataIndex: 'field_num',
                key: 'field_num'
            },
            {
                title: t('header docs'),
                dataIndex: 'field_num',
                key: 'field_num'
            },
            {
                title: t('header comment'),
                dataIndex: 'field_num',
                key: 'field_num'
            },
            {
                title: '',
                key: 'actions',
                render: () => <Button>:</Button>
            }
        ];
    }

    removeCalculator = () => {
        const { removeCalculatorAGRO, getCalculatorsAGRO } = this.props;
        const { deleteId } = this.state;

        removeCalculatorAGRO(deleteId);

        this.hideDeleteModal();
    };

    hideDeleteModal = () => {
        this.setState({ infoMessageVisible: false });
    };

    showDeleteModal = id => {
        this.setState({
            deleteId: id,
            infoMessageVisible: true
        });
    };

    handleObjectsSetup = () => {
        const {
            generalLayer
        } = this.props;

        if (generalLayer && generalLayer > 0) {
            // console.log(generalLayer);
            window.location.replace(`/layer-objects/${generalLayer}/`);
        }
    };

    get filterItems() {
        const { dataSize } = this.props;
        const items = [];

        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }

        return items;
    }

    handleOpenFieldYear = (yearChosen, record) => {
        const {
            openFieldYear
        } = this.props
        const {
            page,
            page_size,
            layer,
            year,
            owner_user,
            culture,
        } = this.state;

        openFieldYear(yearChosen, record, {
            page: page,
            page_size: page_size,
            year: year,
            layer: layer,
            owner_user: owner_user,
            culture: culture
        });
    }

    handleTableChange = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra);
        this.setState({
            sortingOrder: sorter
        }, () => {
            this.setState({ page: 1 }, () => {
                this.getFilteredFields();
            });
        });
        // const { order, columnKey } = sorter;
        // console.log('sorter check ORDER', order);
        // console.log('sorter check COL', columnKey);
    }

    getGeohubLayerInfo = (layerId) => {
        // console.log('Where?', layerId)
        fetch(`${GEOCODE_MAP_URL_BASE}/api/layers/${layerId}`, {
            mode: 'cors',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                // 'Access-Control-Allow-Origin':'localhost:3000',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            return res.json()
        })
        .then((data) => {
            console.log('Got layer data', data)
            // setLayerOptions([...layerOptions, ...data]);
            // if (data?.length === pageSize) {
            //     const newT = setTimeout(() => {
            //         setLayersPage(layersPage + 1);
            //         clearTimeout(newT);
            //     }, 300);
            // }
        });
    };

    selectLayer = (newLayer) => {
        const {
            setGeneralLayer = () => {},
            layerOptions = []
        } = this.props;
        
        const findingLayer = layerOptions.filter(x => x?.id === newLayer)?.[0]
        const geohubLayer = findingLayer.layer_id_ghb;
        console.log('Got geohub ID', geohubLayer)
        this.getGeohubLayerInfo(geohubLayer)
        setGeneralLayer(newLayer)
    }

    // useEffect(() => {
    //     console.log('layer options', layerOptions)
    // }, [layerOptions])

    render() {
        const { cn } = this;
        const {
            setGeneralLayer = () => {},
            activeColumns = [],
            setActiveColumns = () => {},
            dataSource,
            dataSize,
            openModal = () => {},
            layerOptions = [],
            generalLayer,
            syncedLayers = [],
            openField = () => {},
            t
        } = this.props;
        const { infoMessageVisible, page_size, page, layer, year, culture, owner_user } = this.state;

        const actionButton = {
            key: 'actions',
            fixed: 'right',
            // width: 25,
            // ellipsis: true,
            render: (val, record) => record?.year ? (<Button style={{ width: '100%', height: '100%' }} onClick={() => this.handleOpenFieldYear(year, record)}>{t('open')}</Button>) : (<></>)
        };
        
        return layerOptions?.length < 1 ? (
            <NewDataForm openModal={openModal} />
        ) : (
            <div className={cn()}>
                <Form
                    ref={this.formRef}
                    onValuesChange={this.onChange}
                    name="fields">
                        <Row gutter={20}>
                            <Col span={6} className="field">
                                <Space direction="horizontal" style={{ marginBottom: '15px' }}>
                                    <Tooltip placement="topLeft" title={t('plus button tooltip text')}>
                                        <Button onClick={() => openModal(true)} type="primary">+</Button>
                                    </Tooltip>
                                    <div>
                                    Слои полигонов
                                    </div>
                                </Space>
                                {/* <Form.Item
                                    name="layer"
                                    label={(
                                        <Space direction="horizontal">
                                            <Tooltip placement="topLeft" title={t('plus button tooltip text')}>
                                                <Button onClick={() => openModal(true)} type="primary">+</Button>
                                            </Tooltip>
                                            <div>
                                                {t('current layer')}
                                            </div>
                                        </Space>
                                    )}>
                                        <Select
                                            defaultValue={layer}
                                            placeholder={t('select layer placehodler')}
                                            value={generalLayer}
                                            onChange={this.selectLayer}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                                            options={layerOptions?.map(x => {
                                                return {
                                                    label: x?.name,
                                                    value: x?.id
                                                }
                                            })} />
                                </Form.Item> */}
                            </Col>
                            {generalLayer && generalLayer > 0 && (
                                <>
                                    <Col span={6} className="field">
                                        <Form.Item
                                            name="grow_fact"
                                            label={t('grow fact')}>
                                            <Select showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} defaultValue={null} placeholder={t('select year placeholder')} allowClear>
                                                {
                                                    [
                                                        {label: t('option yes'), value: 'present'},
                                                        {label: t('onption no'), value: 'absent'},
                                                        {label: t('option none'), value: null},
                                                    ].map(fact => (<Select.Option value={fact.value}>{fact.label}</Select.Option>))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="field">
                                        <Form.Item
                                            name="care_fact"
                                            label={t('care fact')}>
                                            <Select
                                                defaultValue={null}
                                                // placeholder={t('select owner placeholder')}
                                                showSearch
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                allowClear
                                                options={[
                                                    {label: t('option yes'), value: 'done'},
                                                    {label: t('onption no'), value: 'not_done'},
                                                    {label: t('onption plan'), value: 'planned'},
                                                    {label: t('option none'), value: null},
                                                ]} />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                </Form>

                {generalLayer && generalLayer > 0 ? (
                    <>
                    <Table
                        onChange={this.handleTableChange}
                        rowKey="uniqueRowKey"
                        dataSource={dataSource}
                        columns={[...this.requestsColumnsTwo]}
                        pagination={false} />
                    <TableFooter
                        isSized={false}
                        filterItems={this.filterItems}
                        showSizeChanger={false}
                        onSelect={this.onPageSizeChange}
                        onChange={this.onCurrentPageChange}
                        pageSize={page_size}
                        current={page}
                        rowDivider={ROW_DIVIDER.toString()}
                        dataSize={dataSize} />
                </>
                ): (
                    <>
                        <Table
                            onChange={this.handleTableChange}
                            rowKey="uniqueRowKey"
                            scroll={{ x: 1000 }}
                            dataSource={layerOptions}
                            columns={[...this.requestsColumns]}
                            pagination={false} />
                        <TableFooter
                            isSized={false}
                            filterItems={this.filterItems}
                            showSizeChanger={false}
                            onSelect={this.onPageSizeChange}
                            onChange={this.onCurrentPageChange}
                            pageSize={page_size}
                            current={page}
                            rowDivider={ROW_DIVIDER.toString()}
                            dataSize={dataSize} />
                    </>
                )}
                
                <InfoMessage
                    visible={infoMessageVisible}
                    title={t('delete title')}
                    okBtn={t('delete')}
                    cancelBtn={t('cancel')}
                    onOk={this.removeCalculator}
                    onCancel={this.hideDeleteModal} />
            </div>
        );
    }
}