import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import {
    Layout,
    Breadcrumb,
    Select,
    Form,
    Table,
    Spin,
    Modal,
    Tooltip,
    Button,
    notification
} from 'antd';
import { ArrowLeftOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { GEOCODE_MAP_URL_BASE } from '../../environment';
import Map from '../sampling-points/components/map';
import { Title, PanelLayout } from '../../components';

import request from '../../utils/request';
import { EditableRow, EditableCell } from './components/editable-row';
import './style.less';

const { PanelHeader, PanelContent } = PanelLayout;

function SamplintPointsLayer() {
    const match = useRouteMatch();
    const history = useHistory();

    const navigate = (lId) => {
        history.replace(`/sampling-points-layer/${match.params.id}/${lId}`);
    };

    const goBack = () => {
        history.replace(`/sampling-points/${mapId}/`)
    }

    const { t } = useTranslation('sampling points');

    const [mapExtent, setMapExtent] = useState([]);
    const [mapId, setMapId] = useState(null);
    const [mapAgronomId, setMapAgronomId] = useState(null);
    const [mapName, setMapName] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [layerOptions, setLayerOptions] = useState([]);
    const [columnOptions, setColumnOptions] = useState([]);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentModalLayer, setCurrentModalLayer] = useState(null);
    const [identifyingLayerOptions, setIdentifyingLayerOptions] = useState([]);
    const [currentIdentifyingLayers, setCurrentIdentifyingLayers] = useState([]);

    const [pointLayers, setPointLayers] = useState([]);
    const [currLayer, setCurrLayer] = useState(null);

    const [linkedLayers, setLinkedLayers] = useState([]);
    const [currentLinkedLayer, setCurrentLinkedLayer] = useState(null);

    const [layerObjects, setLayerObjects] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [ownerOptions, setOwnerOptions] = useState([]);
    const [loadingRowId, setLoadingRowId] = useState(null);

    const getLayerInfo = (layerId) => {
      fetch(`${GEOCODE_MAP_URL_BASE}/api/layers/${layerId}`, {
        mode: 'cors',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((data) => {
          const extentArray = [];
          data?.extent?.xMin > 0 && extentArray.push(data?.extent?.xMin);
          data?.extent?.yMin > 0 && extentArray.push(data?.extent?.yMin);
          data?.extent?.xMax > 0 && extentArray.push(data?.extent?.xMax);
          data?.extent?.yMax > 0 && extentArray.push(data?.extent?.yMax);

          if (extentArray?.length > 0) {
            setMapExtent(extentArray);
          }
        });
    };

    useEffect(() => {
      getLayerInfo();
    }, []);

    const getOwnerOptions = async () => {
        const res = await request.get({
            url: '/api/v1/teleagronom_user/owners/'
        });

        if (res.results) {
            const mapped = res.results.map(owner => ({
                label: owner.name,
                value: owner.id
            }));

            setOwnerOptions(mapped);
        }
    };

    useEffect(() => {
        getOwnerOptions();
    }, []);

    useEffect(() => {
        if (match?.params?.id) {
            localStorage.setItem('teleagronom-last-opened-map', match.params.id);
            setMapId(match.params.id);
        } else {
            const lastId = localStorage.getItem('teleagronom-last-opened-map');
            if (lastId) {
                setMapId(match.params.id);
            }
        }
    }, [match.params.id]);

    const getMapAgronomId = async (mapId) => {
        const res = await request.get({
            url: '/api/v1/mordovia/map/',
            body: {
                filter: JSON.stringify({
                    map_id_ghb: mapId
                })
            }
        });

        const maps = res.results;
        if (maps?.length) {
            setMapAgronomId(maps[0].id);
            setMapName(maps[0].name);
        }
    };

    useEffect(() => {
        getMapAgronomId(mapId);
    }, [mapId]);

    const getPointLayers = () =>
        request.get({
            url: '/api/v1/mordovia/layer/',
            body: {
                filter: JSON.stringify({
                    object_type: 'points',
                    map: mapAgronomId,
                    is_deleted: false
                })
            }
        });

    const fetchPointLayers = async () => {
        const res = await getPointLayers();
        if (res.results) {
            setPointLayers(res.results);
        }
    };

    useEffect(() => {
        fetchPointLayers();
    }, [mapAgronomId]);

    const getLayerObjects = async () => {
        try {
            if (currLayer === null || currentLinkedLayer === null) {
                setLayerObjects([]);
                return;
            }
            const res = await request.get({
                url: `/api/v1/mordovia/field_points/?filter={"registry_object.layer":${currLayer}}&filter={"registry_object.layer.linked_layers":[${currentLinkedLayer}]}`
            });
            if (res.results) {
                setLayerObjects(res.results);
            }
        } catch {
            notification.error('Backend error');
        }
    };

    const getLayerObjectsWithLoader = async () => {
        try {
            setIsLoading(true);
            await getLayerObjects();
        } finally {
            setIsLoading(false);
        }
    };

    const setLinked = () => {
        if (match.params.layerId) {
            const target = pointLayers.find(
                (layer) => +layer.id === +match.params.layerId,
            );
            if (target) {
                setLinkedLayers(target.linked_layers);
            }
        }
    };

    useEffect(() => {
        setLinked();
    }, [currLayer]);

    useEffect(() => {
        getLayerObjectsWithLoader();
    }, [currentLinkedLayer]);

    const getDataSource = (layerObjects) => layerObjects.map((obj) => ({
        key: obj.id,
        point_number: obj.point_number,
        region: obj.region?.name,
        district: obj.district?.name,
        field: obj.field_info?.field_num,
        owner: obj.owner?.name,
        user: obj.user ? `${obj.user.first_name} ${obj.user.last_name}` : ''
    }));

    useEffect(() => {
        setDataSource(getDataSource(layerObjects));
    }, [layerObjects]);

    const defaultColumns = [
        {
            title: t('objects.point number col'),
            dataIndex: 'point_number',
            key: 'point_number',
            sorter: (a, b) => b.point_number < a.point_number
        },
        {
            title: t('objects.region col'),
            dataIndex: 'region',
            key: 'region',
            sorter: (a, b) => b.region < a.region
        },
        {
            title: t('objects.district col'),
            dataIndex: 'district',
            key: 'district',
            sorter: (a, b) => b.district < a.district
        },
        {
            title: t('objects.field col'),
            dataIndex: 'field',
            key: 'field',
            sorter: (a, b) => b.field < a.field,
            render: (data) => (
              <div style={data ? {} : { color: 'rgba(0,0,0,0.2)' }}>
                  {data ?? t('objects.no field placeholder')}
              </div>
            )
        },
        {
            title: t('objects.owner col'),
            dataIndex: 'owner',
            key: 'owner',
            sorter: (a, b) => b.owner < a.owner,
            editable: true,
            width: '15%'
        },
        {
            title: t('objects.user col'),
            dataIndex: 'user',
            key: 'user',
            sorter: (a, b) => b.user < a.user
        }
    ];

    const setInitialLinked = () => {
        if (linkedLayers.length === 1) {
            setCurrentLinkedLayer(linkedLayers[0].id);
        } else {
            setCurrentLinkedLayer(null);
        }
    };

    useEffect(() => {
        setInitialLinked();
    }, [linkedLayers]);

    useEffect(() => {
        setLinked();
        if (match.params.layerId) {
            setCurrLayer(+match.params.layerId);
        }
    }, [match.params.layerId, pointLayers]);

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    };

    const handleSave = async (row) => {
        try {
            setLoadingRowId(row.key);
            await request.patch({
                url: `/api/v1/mordovia/field_points/${row.key}/`,
                body: {
                    owner: row.owner
                }
            });

            await getLayerObjects();
        } catch {
            notification.error('Backend error');
        } finally {
            setLoadingRowId(null);
        }
    };

    const [modalForm] = Form.useForm();

    useEffect(() => {
        if (currentModalLayer) {
        getLayerInfo(currentModalLayer);
        }
    }, [currentModalLayer]);

    const clearForm = () => {
        setModalOpen(false);
        modalForm?.setFieldsValue({
        layer: null,
        column: null,
        identifyingLayers: []
        });
        setCurrentModalLayer(null);
        setCurrentColumn(null);
        setCurrentIdentifyingLayers([]);
    };

    const submit = async () => {
        modalForm?.submit();
        try {
        setIsLoading(true);
        await request.post({
            url: '/api/v1/mordovia/upload_object_data/import_geohub_layer/',
            body: {
            map_id: +mapId,
            layer_id: modalForm.getFieldsValue().layer,
            object_type: 'points',
            point_num_column: modalForm.getFieldsValue().column,
            polygons_layer_id: currentIdentifyingLayers
            }
        });
        clearForm();
        setModalOpen(false);
        fetchPointLayers();
        } catch {
        message.error('backend error');
        } finally {
        setIsLoading(false);
        }
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: record.field ? false : col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
                selectOptions: ownerOptions,
                loadingId: loadingRowId
            })
        };
    });

    return (
        <Layout key="page-sampling-points-layer">
            <PanelLayout>
                <PanelHeader>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '20px'
                        }}>
                        <Button type="ghost" onClick={() => goBack()}>
                            <ArrowLeftOutlined />
                        </Button>
                        <Breadcrumb>
                            <Breadcrumb.Item style={{ fontSize: '24px' }}>
                                <Link to="/">{t('breadcrumbs.maps')}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item style={{ fontSize: '24px' }}>
                                <Link to={`/field-registry/${mapId}/`}>{mapName}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item style={{ fontSize: '24px' }}>
                                <Link to={`/sampling-points/${mapId}/`}>{t('title')}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item style={{ fontSize: '24px' }}>
                                {pointLayers.find((l) => l.id === currLayer)?.name ?? currLayer}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </PanelHeader>
                <PanelContent>
                  <Map
                    mapExtent={mapExtent}
                    mapId={mapId}
                    // mapLayer={layerId}
                    mapLayer={null}
                    style={{ width: '100%' }} />
                    <div className="sampling-points__content">
                        <div
                            style={{
                                backgroundColor: '#FAFAFA',
                                borderRadius: '4px',
                                padding: '12px 16px'
                            }}>
                            <Form
                                style={{
                                    display: 'flex',
                                    gap: '12px',
                                    justifyContent: 'flex-start',
                                    width: '600px'
                                }}>
                                <Form.Item
                                    label={t('objects.point layer select label')}
                                    style={{ display: 'block', width: '270px' }}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <Select
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? '')
                                                    ?.toLowerCase()
                                                    .includes(input?.toLowerCase())}
                                            placeholder={t('modal placeholder layer')}
                                            value={currLayer}
                                            options={pointLayers.map((x) => ({
                                                label: x?.name,
                                                value: x?.id
                                            }))}
                                            onChange={(newValue) => {
                                                navigate(newValue);
                                            }}
                                        />
                                        <Button onClick={() => setModalOpen(true)} type="primary">
                                            <PlusOutlined />
                                        </Button>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label={t('objects.linked layer select label')}
                                    required
                                    style={{ display: 'block', width: '320px' }}>
                                    <Select
                                        style={{ width: '270px' }}
                                        showSearch
                                        filterOption={(input, option) =>
                      (option?.label ?? '')
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())}
                                        placeholder={t('objects.linked layer select placeholder')}
                                        value={currentLinkedLayer}
                                        options={linkedLayers.map((x) => ({
                                            label: x?.name,
                                            value: x?.id
                                        }))}
                                        onChange={(newValue) => {
                                            setCurrentLinkedLayer(newValue);
                                        }} />
                                </Form.Item>
                            </Form>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#E6F7FF',
                                border: '1px solid #91D5FF',
                                borderRadius: '4px',
                                padding: '8px 16px'
                            }}>
                {t('objects.info bar text')}
                        </div>
                        <>
                            <div
                                style={{
                                    borderBottom: '1px solid rgba(0,0,0,0.2)',
                                    marginBottom: '20px'
                                }}>
                                <Title
                                    style={{ paddingLeft: '0px' }}
                                    title={(
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px'
                                            }}>
                                            <div style={{ fontSize: '16px' }}>{t('objects.table title')}</div>
                                            <Tooltip
                                                title={t('objects.table title tooltip')}>
                                                <QuestionCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,0.45)',
                                                        fontSize: '20px'
                                                    }} />
                                            </Tooltip>
                                        </div>
                                    )} />
                                <div className="sampling-points__divider" />
                            </div>

                            {isLoading ? (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'grid',
                                        placeItems: 'center'
                                    }}>
                                    <Spin />
                                </div>
                            ) : (
                                <Table
                                    dataSource={dataSource}
                                    columns={columns}
                                    pagination={{ pageSize: 10 }}
                                    components={components}
                                    rowClassName={() => 'editable-row'}
                                    bordered />
                            )}
                        </>
                    </div>
                    <Modal
                        visible={modalOpen}
                        title={(
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <div>{t('modal title')}</div>
                            <Tooltip title={t('modal tooltip')}>
                            <QuestionCircleOutlined
                                style={{
                                display: 'grid',
                                placeItems: 'center',
                                color: 'rgba(0,0,0,0.45)'
                                }} />
                            </Tooltip>
                        </div>
                        )}
                        onOk={submit}
                        onCancel={() => {
                        if (!isLoading) {
                            clearForm();
                        }
                        }}
                        footer={[
                        <Button
                            key="back"
                            onClick={() => {
                            if (!isLoading) {
                                clearForm();
                            }
                            }}>
                            {t('cancel button')}
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={isLoading}
                            onClick={submit}>
                            {t('ok button')}
                        </Button>
                        ]}>
                        <Form labelCol={{ span: 24 }} form={modalForm}>
                        <Form.Item
                            name="layer"
                            label={t('layer modal header')}
                            rules={[{ required: true, message: t('please fill in') }]}
                            tooltip={<div>{t('layer modal header tooltip')}</div>}>
                            <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '')
                                ?.toLowerCase()
                                .includes(input?.toLowerCase())}
                            placeholder={t('modal placeholder layer')}
                            value={currentModalLayer}
                            options={layerOptions
                                .filter((x) => x?.mapId === Number(match?.params?.id))
                                .map((x) => ({
                                    label: x?.name,
                                    value: x?.id
                                }))}
                            onChange={(newValue) => {
                                modalForm?.resetFields();
                                // setCurrentType(null);
                                setCurrentColumn(null);
                                setLayerId(newValue);
                                setCurrentModalLayer(newValue);
                                modalForm.setFieldsValue({ layer: newValue });
                            }} />
                        </Form.Item>
                        <Form.Item
                            name="column"
                            label={t('column modal header')}
                            rules={[{ required: true, message: t('please fill in') }]}
                            tooltip={<div>{t('column modal header tooltip')}</div>}>
                            <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '')
                                ?.toLowerCase()
                                .includes(input?.toLowerCase())}
                            placeholder={t('modal placeholder column')}
                            value={currentColumn}
                            style={{ width: '100%' }}
                            options={columnOptions}
                            onChange={setCurrentColumn} />
                        </Form.Item>
                        <Form.Item
                            name="identifyingLayers"
                            label={t('identifying layer modal header')}
                            rules={[{ required: false, message: t('please fill in') }]}
                            tooltip={
                            <div>{t('identifying layer modal header tooltip')}</div>
                            }>
                            <Select
                            mode="multiple"
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '')
                                ?.toLowerCase()
                                .includes(input?.toLowerCase())}
                            placeholder={t('modal placeholder identifying layer')}
                            value={currentIdentifyingLayers}
                            style={{ width: '100%' }}
                            options={identifyingLayerOptions}
                            onChange={setCurrentIdentifyingLayers} />
                        </Form.Item>
                        </Form>
                    </Modal>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
}

export default SamplintPointsLayer;
